var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex space-x-4" }, [
    _vm.testPhase > 1
      ? _c(
          "div",
          {
            staticClass:
              "inline-flex items-center bg-gray-50 space-x-1 rounded-full px-3 py-1 text-sm text-gray-800 mr-2",
          },
          [
            _c("fv-icon", {
              class: _vm.connectivity ? "text-fv-green" : "text-fv-red",
              attrs: { icon: _vm.connectivity ? "check-mark-alt" : "warning" },
            }),
            _c("span", [
              _vm._v(_vm._s(_vm.connectivity ? "Connection" : "No connection")),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.testPhase > 2 && _vm.results
      ? _c(
          "div",
          {
            staticClass:
              "inline-flex items-center bg-gray-50 space-x-1  rounded-full px-3 py-1 text-sm text-gray-800 mr-2",
          },
          [
            _c("fv-icon", {
              class:
                _vm.results.video && _vm.results.video.supported
                  ? "text-fv-green"
                  : "text-fv-red",
              attrs: {
                icon:
                  _vm.results.video && _vm.results.video.supported
                    ? "check-mark-alt"
                    : "warning",
              },
            }),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.results.video && _vm.results.video.supported
                    ? "Video"
                    : "No video"
                )
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.testPhase > 2 && _vm.results
      ? _c(
          "div",
          {
            staticClass:
              "inline-flex items-center bg-gray-50 space-x-1  rounded-full px-3 py-1 text-sm text-gray-800 mr-2",
          },
          [
            _c("fv-icon", {
              class:
                _vm.results.audio && _vm.results.audio.supported
                  ? "text-fv-green"
                  : "text-fv-red",
              attrs: {
                icon:
                  _vm.results.audio && _vm.results.audio.supported
                    ? "check-mark-alt"
                    : "warning",
              },
            }),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.results.audio && _vm.results.audio.supported
                    ? "Audio"
                    : "No audio"
                )
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }