<template>
  <div class="mt-4 ">
    <table class="text-left w-full border-collapse">
      <thead>
        <tr>
          <th
            v-for="(thead, i) in theads"
            :key="i"
            class="py-1 font-bold uppercase text-sm text-gray-700 border-b"
          >
            {{ thead }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="">
          <td class="py-1 px-2">
            <p class="text-base font-bold mb-2 text-primary-dark">
              <fv-icon icon="video" />
            </p>
          </td>
          <td>
            <span v-if="stats.video">{{ (Math.round(stats.video.bytesReceived * 10) / 10 ) / 1000 }} kbs</span>
          </td>
          <td>
            <template v-if="stats.video">
              {{ stats.video.packetsReceived }} / {{ stats.video.packetsLost }}
            </template>
          </td>
        </tr>

        <tr class="">
          <td class="py-1 px-2">
            <p class="text-base font-bold mb-2 text-primary-dark">
              <fv-icon icon="microphone" />
            </p>
          </td>
          <td>
            <span v-if="stats.audio">{{ (Math.round(stats.audio.bytesReceived * 10) / 10 ) / 1000 }} kbs</span>
          </td>
          <td>
            <template v-if="stats.audio">
              {{ stats.audio.packetsReceived }} / {{ stats.audio.packetsLost }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      theads: ['', 'Kbps', 'Packets recieved/lost'],
    };
  },
};
</script>
