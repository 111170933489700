var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-gray-800 rounded w-full" }, [
    _c("div", { staticClass: "flex flex-wrap space-x-4" }, [
      _c("div", { staticClass: "flex justify-center" }, [
        _vm.audioDevices.length
          ? _c(
              "ul",
              { staticClass: "flex-1 list-none mx-2" },
              [
                _c("li", { staticClass: "mb-4" }, [_vm._v(" Audio devices ")]),
                _vm._l(_vm.audioDevices, function (item) {
                  return _c(
                    "li",
                    {
                      key: item.id,
                      staticClass: "mb-3 text-base flex items-center space-x-1",
                      class: item.deviceId == "default" ? "underline" : "",
                    },
                    [
                      _c("fv-icon", { attrs: { icon: "microphone" } }),
                      _c("span", [_vm._v(" " + _vm._s(item.label))]),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "flex justify-center" }, [
        _vm.videoDevices.length
          ? _c(
              "ul",
              { staticClass: "flex-1 list-none mx-2" },
              [
                _c("li", { staticClass: "mb-4" }, [_vm._v(" Video devices ")]),
                _vm._l(_vm.videoDevices, function (item) {
                  return _c(
                    "li",
                    {
                      key: item.id,
                      staticClass: "mb-3 text-base flex items-center space-x-1",
                      class: item.deviceId == "default" ? "underline" : "",
                    },
                    [
                      _c("fv-icon", { attrs: { icon: "video" } }),
                      _c("span", [_vm._v(" " + _vm._s(item.label))]),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "flex w-full mt-2" },
      [
        _c(
          "base-button",
          {
            staticClass: "w-full",
            attrs: { color: "light", loading: _vm.loading },
            on: { click: _vm.fetchData },
          },
          [_c("span", [_vm._v("Scan")])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }