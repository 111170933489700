var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex font-semibold h-full py-2 justify-between" },
    [
      _c(
        "div",
        { staticClass: "mx-4 text-center inline-flex items-center space-x-2" },
        [
          _c("fv-icon", {
            staticClass: "text-green-700",
            attrs: { icon: "check-mark-alt" },
          }),
          _c("h4", [_vm._v("Connection OK")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mx-4 text-center inline-flex items-center space-x-2" },
        [
          _c("fv-icon", {
            class: _vm.results.video.reason ? "text-fv-red" : "text-fv-green",
            attrs: {
              icon: _vm.results.video.reason ? "video-disabled" : "video",
            },
          }),
          _vm.results.video.reason
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "Video not supported - " + _vm._s(_vm.results.video.reason)
                  ),
                ]),
              ])
            : _c("div", [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.results.video.mos.toFixed(2)) +
                      " - " +
                      _vm._s(_vm.videoScore.text)
                  ),
                ]),
              ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mx-4 text-center inline-flex items-center space-x-2 " },
        [
          _c("fv-icon", {
            class: _vm.results.audio.reason ? "text-fv-red" : "text-fv-green",
            attrs: {
              icon: _vm.results.audio.reason
                ? "microphone-disabled"
                : "microphone",
            },
          }),
          _vm.results.audio.reason
            ? _c("div", { staticClass: "mx-2" }, [
                _c("p", [
                  _vm._v(
                    "Audio not supported - " + _vm._s(_vm.results.audio.reason)
                  ),
                ]),
              ])
            : _c("div", [
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.results.audio.mos.toFixed(2)) +
                      " - " +
                      _vm._s(_vm.audioScore.text)
                  ),
                ]),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }