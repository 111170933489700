<template>
  <div class="text-gray-800 rounded w-full">
    <div
      class="flex flex-wrap space-x-4"
    >
      <div class="flex justify-center">
        <ul
          v-if="audioDevices.length"
          class="flex-1 list-none mx-2"
        >
          <li class="mb-4">
            Audio devices
          </li>
          <li
            v-for="item in audioDevices"
            :key="item.id"
            class="mb-3 text-base flex items-center space-x-1"
            :class="item.deviceId == 'default' ? 'underline' : ''"
          >
            <fv-icon icon="microphone" /><span> {{ item.label }}</span>
          </li>
        </ul>
      </div>
      <div class="flex justify-center">
        <ul
          v-if="videoDevices.length"
          class="flex-1 list-none mx-2"
        >
          <li class="mb-4">
            Video devices
          </li>
          <li
            v-for="item in videoDevices"
            :key="item.id"
            class="mb-3 text-base flex items-center space-x-1"
            :class="item.deviceId == 'default' ? 'underline' : ''"
          >
            <fv-icon icon="video" /><span> {{ item.label }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex w-full mt-2">
      <base-button
        color="light"
        :loading="loading"
        class="w-full"
        @click="fetchData"
      >
        <span>Scan</span>
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    otNetworkTest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('opentok', ['audioDevices', 'videoDevices']),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions('opentok', ['getDevices']),
    fetchData() {
      this.loading = true;
      this.getDevices()
        .then(res => {
          console.log('Checked devices', res);
        })
        .catch(e => {
          this.$notify({
            group: 'error',
            text: e.message,
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },
};
</script>

<style lang="postcss">
</style>
