var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-4 " }, [
    _c("table", { staticClass: "text-left w-full border-collapse" }, [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.theads, function (thead, i) {
            return _c(
              "th",
              {
                key: i,
                staticClass:
                  "py-1 font-bold uppercase text-sm text-gray-700 border-b",
              },
              [_vm._v(" " + _vm._s(thead) + " ")]
            )
          }),
          0
        ),
      ]),
      _c("tbody", [
        _c("tr", {}, [
          _c("td", { staticClass: "py-1 px-2" }, [
            _c(
              "p",
              { staticClass: "text-base font-bold mb-2 text-primary-dark" },
              [_c("fv-icon", { attrs: { icon: "video" } })],
              1
            ),
          ]),
          _c("td", [
            _vm.stats.video
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      Math.round(_vm.stats.video.bytesReceived * 10) / 10 / 1000
                    ) + " kbs"
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "td",
            [
              _vm.stats.video
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.stats.video.packetsReceived) +
                        " / " +
                        _vm._s(_vm.stats.video.packetsLost) +
                        " "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c("tr", {}, [
          _c("td", { staticClass: "py-1 px-2" }, [
            _c(
              "p",
              { staticClass: "text-base font-bold mb-2 text-primary-dark" },
              [_c("fv-icon", { attrs: { icon: "microphone" } })],
              1
            ),
          ]),
          _c("td", [
            _vm.stats.audio
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      Math.round(_vm.stats.audio.bytesReceived * 10) / 10 / 1000
                    ) + " kbs"
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "td",
            [
              _vm.stats.audio
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.stats.audio.packetsReceived) +
                        " / " +
                        _vm._s(_vm.stats.audio.packetsLost) +
                        " "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }