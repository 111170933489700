<template>
  <div class="flex font-semibold h-full py-2 justify-between">
    <div class="mx-4 text-center inline-flex items-center space-x-2">
      <fv-icon
        icon="check-mark-alt"
        class="text-green-700"
      />
      <h4>Connection OK</h4>
    </div>

    <div class="mx-4 text-center inline-flex items-center space-x-2">
      <fv-icon
        :icon="results.video.reason ? 'video-disabled' : 'video'"
        :class="results.video.reason ? 'text-fv-red' : `text-fv-green`"
      />
      <div v-if="results.video.reason">
        <p>Video not supported - {{ results.video.reason }}</p>
      </div>
      <div v-else>
        <h4>{{ results.video.mos.toFixed(2) }} - {{ videoScore.text }}</h4>
      </div>
    </div>

    <div class="mx-4 text-center inline-flex items-center space-x-2 ">
      <fv-icon
        :icon="results.audio.reason ? 'microphone-disabled' : 'microphone'"
        :class="results.audio.reason ? 'text-fv-red' : `text-fv-green`"
      />
      <div
        v-if="results.audio.reason"
        class="mx-2"
      >
        <p>Audio not supported - {{ results.audio.reason }}</p>
      </div>
      <div v-else>
        <h4>{{ results.audio.mos.toFixed(2) }} - {{ audioScore.text }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { rateMosScore } from '@/utils/helpers/connection-stats-helpers';

export default {
  props: {
    results: {
      type: Object,
      required: true,
    },
  },
  computed: {
    audioScore() {
      return rateMosScore(this.results.audio.mos);
    },
    videoScore() {
      return rateMosScore(this.results.video.mos);
    },
  },
};
</script>
