<template>
  <div class="w-full overflow-hidden rounded bg-white shadow">
    <div
      class="flex w-full flex-shrink-0 flex-wrap content-center justify-center bg-fv-black"
      style="min-height: 512px"
    >
      <div
        v-if="!showPublisher"
        class="flex h-full w-full flex-col items-center justify-center"
      >
        <div
          class="mt-4 py-8 text-center font-display text-2xl font-black text-white"
        >
          <span v-if="!testRunning">Network test & audio/video diagnosis</span>
          <base-spinner v-if="testRunning" class="text-2xl text-white" />
        </div>
      </div>
      <transition name="slide-fade">
        <div v-show="showPublisher" class="flex h-full w-full justify-center">
          <div
            id="publisherDiv"
            class="relative h-full w-full"
            style="min-height: 362px"
          />
        </div>
      </transition>
      <div
        v-if="results && !webRTCSupported"
        class="pt-8 text-base text-gray-800"
      >
        <h3 class="mb-4 text-center">
          There seems to be problems with your audio/video devices.
        </h3>
        <diagnosis-results :results="results" />
      </div>
    </div>

    <div class="px-6 py-4">
      <div class="mb-2 text-base font-semibold text-gray-700">
        {{ statusText }}
      </div>

      <ul v-if="errorMessages.length">
        <li
          v-for="(error, index) in errorMessages"
          :key="index"
          class="text-red-dark"
        >
          {{ error }}
        </li>
      </ul>

      <transition name="slide-fade">
        <div v-if="results && webRTCSupported" class="text-base text-gray-900">
          <diagnosis-results :results="results" />
        </div>
      </transition>

      <transition name="slide-fade">
        <div v-if="testRunning">
          <diagnosis-live-stats v-if="stats" :stats="stats" />
        </div>
      </transition>

      <div v-show="testPhase == 0" class="text-base text-gray-800">
        The pre-call test checks your internet connectivity and speed. It also
        runs a simple diagnosis test to check microphone and camera quality.
      </div>
    </div>

    <div class="px-6 py-4">
      <div class="flex">
        <base-button
          v-if="!testRunning && !showPublisher"
          :loading="testRunning || loadingTest"
          class="w-full"
          color="primary"
          @click="startTest"
        >
          {{ !loadingTest ? 'Start' : 'Loading test' }}
        </base-button>
      </div>
      <div class="flex">
        <base-button
          v-if="testRunning || showPublisher"
          :color="testRunning ? 'cancel' : 'light'"
          class="w-full"
          @click="stopTest"
        >
          <span v-if="testRunning">Cancel</span>
          <span v-if="showPublisher && !testRunning">Reset</span>
        </base-button>
      </div>
      <transition name="slide-fade">
        <diagnosis-checks
          v-if="testPhase > 1"
          class="mt-4 flex justify-between"
          :test-phase="testPhase"
          :results="results"
          :connectivity="connectivity"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { ErrorNames } from 'opentok-network-test-js';
import DiagnosisChecks from './DiagnosisChecks.vue';
import DiagnosisResults from './DiagnosisResults.vue';
import DiagnosisLiveStats from './DiagnosisLiveStats.vue';

export default {
  components: {
    DiagnosisChecks,
    DiagnosisResults,
    DiagnosisLiveStats,
  },
  props: {
    otNetworkTest: {
      type: Object,
      required: true,
    },
    loadingTest: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      stats: null,
      publisherSettings: {
        width: '100%',
        height: '100%',
        showControls: true,
        frameRate: 30,
        resolution: '640x480',
        fitMode: 'contain',
        style: {
          audioLevelDisplayMode: 'on',
        },
        insertMode: 'append',
      },
      publisher: null,
      connectivity: false,
      results: null,
      testPhase: 0,
      showPublisher: false,
      errorMessages: [],
      shouldAbort: false,
    };
  },
  computed: {
    testRunning() {
      return this.testPhase > 0 && this.testPhase < 3;
    },

    statusText() {
      switch (this.testPhase) {
        case 0:
          return 'Ready to start';
        case 1:
          return 'Checking connection..';
        case 2:
          return 'Running audio & video diagnosis..';
        case 3:
          return '';
        default:
          return 'Start test';
      }
    },

    webRTCSupported() {
      if (this.results) {
        return this.results.video.supported && this.results.audio.supported;
      }
      return false;
    },
  },
  beforeDestroy() {
    this.stopTest();
  },
  methods: {
    resetData() {
      this.testPhase = 0;
      this.stats = null;
      this.results = null;
      this.publisher = null;
      this.showPublisher = false;
      this.errorMessages = [];
    },
    startTest() {
      this.resetData();
      this.shouldAbort = false;
      this.testPhase = 1;

      this.otNetworkTest
        .testConnectivity()
        .then(connectivity => {
          if (this.shouldAbort) {
            return;
          }

          this.connectivity = connectivity.success;

          if (!this.connectivity) {
            connectivity.failedTests.forEach(failedTest => {
              this.handleError(failedTest.error);
            });
            this.testPhase = 3;
            return;
          }

          const vm = this;
          this.testPhase = 2;
          this.otNetworkTest
            .testQuality(function updateCallback(stats) {
              vm.stats = stats;
            })
            .then(results => {
              if (this.shouldAbort) {
                return;
              }

              this.results = results;

              if (results && results.video) {
                const { recommendedFrameRate, recommendedResolution } =
                  results.video;

                if (recommendedFrameRate) {
                  this.publisherSettings.frameRate = recommendedFrameRate;
                }

                if (recommendedResolution) {
                  this.publisherSettings.resolution = recommendedResolution;
                }
              }

              // Show video with recommended quality
              this.initPublisher();
            })
            .catch(error => {
              this.handleError(error);
            })
            .finally(() => {
              this.testPhase = 3;
            });
        })
        .catch(error => {
          console.log('OpenTok connectivity test error', error);
        });
    },
    stopTest() {
      this.shouldAbort = true;

      // if (this.otNetworkTest) {
      //   this.otNetworkTest.stop();
      // }

      if (this.publisher) {
        this.publisher.destroy();
      }

      this.resetData();
    },

    initPublisher() {
      this.publisher = this.otNetworkTest.OT.initPublisher(
        'publisherDiv',
        this.publisherSettings,
        err => {
          if (err) {
            console.error('ERROR in creating publisher', err);
          } else {
            this.showPublisher = true;
          }
        }
      );
    },

    handleError(error) {
      switch (error.name) {
        case ErrorNames.FAILED_TO_CREATE_LOCAL_PUBLISHER:
        case ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES:
          this.errorMessages.push(
            'Failed to connect to your camera and/or mic. Have you granted access to them?'
          );
          break;
        case ErrorNames.UNSUPPORTED_BROWSER:
          this.errorMessages.push('Your browser is not supported.');
          break;
        case ErrorNames.CONNECT_TO_SESSION_NETWORK_ERROR:
          this.errorMessages.push(
            'Network error. Please check your internet connection.'
          );
          break;
        case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
          this.errorMessages.push('Could not find an available camera.');
          break;
        case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
          this.errorMessages.push('Could not find an available mic.');
          break;
        default:
          this.errorMessages.push(error.message);
      }
    },
  },
};
</script>

<style lang="scss">
#publisherDiv .OT_publisher {
  position: initial;
}
</style>
