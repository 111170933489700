var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full overflow-hidden rounded bg-white shadow" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex w-full flex-shrink-0 flex-wrap content-center justify-center bg-fv-black",
          staticStyle: { "min-height": "512px" },
        },
        [
          !_vm.showPublisher
            ? _c(
                "div",
                {
                  staticClass:
                    "flex h-full w-full flex-col items-center justify-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 py-8 text-center font-display text-2xl font-black text-white",
                    },
                    [
                      !_vm.testRunning
                        ? _c("span", [
                            _vm._v("Network test & audio/video diagnosis"),
                          ])
                        : _vm._e(),
                      _vm.testRunning
                        ? _c("base-spinner", {
                            staticClass: "text-2xl text-white",
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showPublisher,
                    expression: "showPublisher",
                  },
                ],
                staticClass: "flex h-full w-full justify-center",
              },
              [
                _c("div", {
                  staticClass: "relative h-full w-full",
                  staticStyle: { "min-height": "362px" },
                  attrs: { id: "publisherDiv" },
                }),
              ]
            ),
          ]),
          _vm.results && !_vm.webRTCSupported
            ? _c(
                "div",
                { staticClass: "pt-8 text-base text-gray-800" },
                [
                  _c("h3", { staticClass: "mb-4 text-center" }, [
                    _vm._v(
                      " There seems to be problems with your audio/video devices. "
                    ),
                  ]),
                  _c("diagnosis-results", { attrs: { results: _vm.results } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-6 py-4" },
        [
          _c(
            "div",
            { staticClass: "mb-2 text-base font-semibold text-gray-700" },
            [_vm._v(" " + _vm._s(_vm.statusText) + " ")]
          ),
          _vm.errorMessages.length
            ? _c(
                "ul",
                _vm._l(_vm.errorMessages, function (error, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "text-red-dark" },
                    [_vm._v(" " + _vm._s(error) + " ")]
                  )
                }),
                0
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _vm.results && _vm.webRTCSupported
              ? _c(
                  "div",
                  { staticClass: "text-base text-gray-900" },
                  [
                    _c("diagnosis-results", {
                      attrs: { results: _vm.results },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _vm.testRunning
              ? _c(
                  "div",
                  [
                    _vm.stats
                      ? _c("diagnosis-live-stats", {
                          attrs: { stats: _vm.stats },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.testPhase == 0,
                  expression: "testPhase == 0",
                },
              ],
              staticClass: "text-base text-gray-800",
            },
            [
              _vm._v(
                " The pre-call test checks your internet connectivity and speed. It also runs a simple diagnosis test to check microphone and camera quality. "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-6 py-4" },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              !_vm.testRunning && !_vm.showPublisher
                ? _c(
                    "base-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        loading: _vm.testRunning || _vm.loadingTest,
                        color: "primary",
                      },
                      on: { click: _vm.startTest },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(!_vm.loadingTest ? "Start" : "Loading test") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _vm.testRunning || _vm.showPublisher
                ? _c(
                    "base-button",
                    {
                      staticClass: "w-full",
                      attrs: { color: _vm.testRunning ? "cancel" : "light" },
                      on: { click: _vm.stopTest },
                    },
                    [
                      _vm.testRunning
                        ? _c("span", [_vm._v("Cancel")])
                        : _vm._e(),
                      _vm.showPublisher && !_vm.testRunning
                        ? _c("span", [_vm._v("Reset")])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "slide-fade" } },
            [
              _vm.testPhase > 1
                ? _c("diagnosis-checks", {
                    staticClass: "mt-4 flex justify-between",
                    attrs: {
                      "test-phase": _vm.testPhase,
                      results: _vm.results,
                      connectivity: _vm.connectivity,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }