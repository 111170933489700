<template>
  <narrow-layout>
    <div class="flex flex-col space-y-8">
      <network-diagnosis
        :ot-network-test="otNetworkTest"
        class="mx-auto border"
        :loading-test="loadingTest"
      />

      <device-list
        class="mx-auto border bg-white p-4 shadow"
        :ot-network-test="otNetworkTest"
      />
    </div>
  </narrow-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NetworkTest from 'opentok-network-test-js';
import OT from '@opentok/client';
import NetworkDiagnosis from '@/components/diagnosis/NetworkDiagnosis';
import DeviceList from '@/components/diagnosis/DeviceList';
import AppointmentApi from '@/api/modules/appointment';

export default {
  components: {
    NetworkDiagnosis,
    DeviceList,
  },
  data() {
    return {
      otNetworkTest: {},
      loadingTest: false,
    };
  },
  computed: {
    ...mapState(['platform']),
    ...mapGetters('opentok', ['getApiKey']),
  },
  mounted() {
    if (!Object.keys(this.otNetworkTest).length) {
      this.initalizeTest();
    }
  },
  methods: {
    initalizeTest() {
      this.loadingTest = true;
      AppointmentApi.fetchTestCredentials()
        .then(res => {
          this.otNetworkTest = new NetworkTest(OT, {
            OT,
            apiKey: this.getApiKey,
            sessionId: res.data.sessionID, // Add a test session ID for that project
            token: res.data.token, // Add a token for that session here
          });
        })
        .catch(e => {
          console.error(e);
          return e;
        })
        .finally(() => {
          this.loadingTest = false;
        });
    },
  },
};
</script>

<style lang="postcss"></style>
