const getPacketsLost = ts =>
  (ts && ts.packetsLost) || (ts && ts.packetsSent) || 0;
const getPacketsReceived = ts =>
  (ts && ts.packetsReceived) || (ts && ts.packetsSent) || 0;
const getTotalPackets = ts => getPacketsLost(ts) + getPacketsReceived(ts);
const calculateTotalPackets = (type, current, last) =>
  getTotalPackets(current[type]) - getTotalPackets(last[type]);
const calculateBitRate = (type, current, prev) => {
  const interval = current.timestamp - prev.timestamp;

  const totalBytes =
    (current[type] && current[type].bytesReceived) ||
    (current[type] && current[type].bytesSent);

  const prevTotalBytes =
    (prev[type] && prev[type].bytesReceived) ||
    (prev[type] && prev[type].bytesSent);

  return totalBytes
    ? (8 * (totalBytes - prevTotalBytes)) / (interval / 1000)
    : 0;
};

const targetBitrateForPixelCount = pixelCount => {
  // power function maps resolution to target bitrate, based on rumor config
  // values, with r^2 = 0.98. We're ignoring frame rate, assume 30.
  const y = 2.069924867 * Math.log10(pixelCount) ** 0.6250223771;
  return 10 ** y;
};

const calculateVideoScore = (subscriber, stats) => {
  const MIN_VIDEO_BITRATE = 30000;

  const currentStats = stats[stats.length - 1];
  const lastStats = stats[stats.length - 2];

  if (!currentStats || !lastStats || !subscriber) {
    return 1;
  }

  const baseBitrate = calculateBitRate('video', currentStats, lastStats);
  const pixelCount =
    subscriber.stream.videoDimensions.width *
    subscriber.stream.videoDimensions.height;
  const targetBitrate = targetBitrateForPixelCount(pixelCount);

  if (baseBitrate < MIN_VIDEO_BITRATE) {
    return 1;
  }
  const bitrate = Math.min(baseBitrate, targetBitrate);

  const score =
    (Math.log(bitrate / MIN_VIDEO_BITRATE) /
      Math.log(targetBitrate / MIN_VIDEO_BITRATE)) *
      4 +
    1;

  return { mos: Math.round(score * 1e2) / 1e2, bitrate };
};

const calculateAudioScore = stats => {
  const audioScore = (roundTripTime, packetLossRatio) => {
    const LOCAL_DELAY = 20; // 20 msecs: typical frame duration
    const h = x => (x < 0 ? 0 : 1);
    const a = 0; // ILBC: a=10
    const b = 19.8;
    const c = 29.7;

    /**
     * Calculate the transmission rating factor, R
     */
    const calculateR = () => {
      const d = roundTripTime + LOCAL_DELAY;
      const delayImpairment = (0.024 * d + 0.11) * (d - 177.3) * h(d - 177.3);
      const equipmentImpairment = (a + b) * Math.log(1 + c * packetLossRatio);
      return 94.2 - delayImpairment - equipmentImpairment;
    };

    /**
     * Calculate the Mean Opinion Score based on R
     */
    const calculateMOS = R => {
      if (R < 0) {
        return 1;
      }
      if (R > 100) {
        return 4.5;
      }
      return (
        Math.round(
          (1 + 0.035 * R + (7.1 / 1000000) * R * (R - 60) * (100 - R)) * 1e2
        ) / 1e2
      );
    };

    return calculateMOS(calculateR());
  };

  const currentStats = stats[stats.length - 1];
  const lastStats = stats[stats.length - 2];

  if (!currentStats || !lastStats) {
    return 0;
  }

  const totalAudioPackets = calculateTotalPackets(
    'audio',
    currentStats,
    lastStats
  );

  if (totalAudioPackets === 0) {
    return 0;
  }
  const packetLossRatio =
    (getPacketsLost(currentStats.audio) - getPacketsLost(lastStats.audio)) /
    totalAudioPackets;
  return audioScore(0, packetLossRatio);
};

const rateMosScore = mos => {
  switch (true) {
    case mos === 0:
      return {
        text: 'n/a',
        color: 'text-gray-700',
        value: mos,
      };
    case mos < 1.7:
      return {
        text: 'Bad',
        color: 'text-red-700',
        value: mos,
      };
    case mos < 2.4:
      return {
        text: 'Poor',
        color: 'text-yellow-500',
        value: mos,
      };
    case mos < 3.1:
      return {
        text: 'Fair',
        color: 'text-yellow-500',
        value: mos,
      };
    case mos < 3.8:
      return {
        text: 'Good',
        color: 'text-fv-green',
        value: mos,
      };
    case mos > 3.8:
      return {
        text: 'Excellent',
        color: 'text-fv-green',
        value: mos,
      };
    default:
      return {
        text: 'n/a',
        color: 'text-gray-700',
        value: mos,
      };
  }
};

export { calculateVideoScore, calculateAudioScore, rateMosScore };
