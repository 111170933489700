<template>
  <div class="flex space-x-4">
    <div
      v-if="testPhase > 1"
      class="inline-flex items-center bg-gray-50 space-x-1 rounded-full px-3 py-1 text-sm text-gray-800 mr-2"
    >
      <fv-icon
        :icon="connectivity ? 'check-mark-alt' : 'warning'"
        :class="connectivity ? 'text-fv-green' : 'text-fv-red'"
      />
      <span>{{ connectivity ? 'Connection' : 'No connection' }}</span>
    </div>

    <div
      v-if="testPhase > 2 && results"
      class="inline-flex items-center bg-gray-50 space-x-1  rounded-full px-3 py-1 text-sm text-gray-800 mr-2"
    >
      <fv-icon
        :icon="results.video && results.video.supported ? 'check-mark-alt' : 'warning'"
        :class="results.video && results.video.supported ? 'text-fv-green' : 'text-fv-red'"
      />
      <span>{{ results.video && results.video.supported ? 'Video' : 'No video' }}</span>
    </div>

    <div
      v-if="testPhase > 2 && results"
      class="inline-flex items-center bg-gray-50 space-x-1  rounded-full px-3 py-1 text-sm text-gray-800 mr-2"
    >
      <fv-icon
        :icon="results.audio && results.audio.supported ? 'check-mark-alt' : 'warning'"
        :class="results.audio && results.audio.supported ? 'text-fv-green' : 'text-fv-red'"
      />
      <span>{{ results.audio && results.audio.supported ? 'Audio' : 'No audio' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testPhase: {
      type: Number,
      required: true,
    },
    connectivity: {
      type: Boolean,
      default: false,
    },
    results: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="postcss">
</style>
